import React, { useState } from "react"
import styled from "styled-components"

const AutocompleteContainer = styled.div`
  position: relative;
  .suggestions {
    max-height: 100px;
    overflow: auto;
    position: absolute;
    width: 100%;
    background: #fff;
    list-style: none;
    text-align: left;
    padding-left: 14px;
    border-radius: 4px;
    border: 1px solid #eee;
    li {
      padding: 8px 0;
      cursor: pointer;
    }
  }
`
// TODO make it more generic
const Autocomplete = ({ suggestions, onSuggestionSelected, onInputChange }) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState()
  const [currentInput, setCurrentInput] = useState()

  const handleChange = e => {
    const currentInput = e.target.value

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.state.toLowerCase().includes(currentInput.toLowerCase()) ||
        suggestion.city.toLowerCase().includes(currentInput.toLowerCase())
    )
    setFilteredSuggestions(filteredSuggestions)
    setActiveSuggestion(0)
    setShowSuggestions(filteredSuggestions.length === 0 ? false : true)
    setCurrentInput(currentInput)
    onInputChange(currentInput)
  }

  const onSuggestionClick = selected => {
    setFilteredSuggestions([])
    setActiveSuggestion(0)
    setShowSuggestions(false)
    setCurrentInput(selected)
    onSuggestionSelected(selected)
  }

  const renderSuggestionList = () => {
    return (
      <ul class="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className
          if (index === activeSuggestion) {
            className = "suggestion-active"
          }

          return (
            <li
              className={className}
              key={index}
              onClick={() =>
                onSuggestionClick(`${suggestion.city},${suggestion.state}`)
              }
            >
              {`${suggestion.city},${suggestion.state}`}
            </li>
          )
        })}
      </ul>
    )
  }
  return (
    <AutocompleteContainer>
      <input
        id="product-location"
        type="text"
        onChange={handleChange}
        value={currentInput}
        placeholder="Product Location"
      />
      {showSuggestions && currentInput && renderSuggestionList()}
    </AutocompleteContainer>
  )
}

export default Autocomplete
