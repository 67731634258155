import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import { PosedError } from "../../pages/createaccount"
import Autocomplete from "../Autocomplete"
import { usCitiesStates } from "../../utils/usCitieStates"

const AccountCreationInfoContainer = styled.div`
  .hear-about-us-wrapper {
    padding: 0 15px;
  }
  .section-wrapper {
    margin-bottom: 50px;
    label {
      display: inline-block !important;
    }
    .info-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 30px;
      text-align: center;
    }
    .hear-about-options {
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
    }
    .info-subtitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 32px;
      text-align: center;
    }
    input {
      font-size: 14px;
      padding: 12px;
    }
    .button {
      width: 100%;
      text-align: center;

      box-sizing: border-box;
      border-radius: 4px;

      font-size: 14px;
      line-height: 16px;
      font-weight: 700;

      padding: 12px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
      &:disabled {
        background: #b0b0b0;
        // transform: scale(0.95);
      }
    }
    .button--continue {
      color: #fff;
      background-color: rgb(102, 122, 244);
      border: 1px solid transparent;
    }
    .optional-text {
      color: #ea3324;
      text-align: left;
    }
  }
`

const CheckboxContainer = styled.div`
  // margin-bottom: 20px;
  flex: 0 0 48%;
  text-align: left;
  margin-left: 2%;
  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;

    padding-left: 16px;
    cursor: pointer;
    font-size: 14px;
  }

  input:disabled + label {
    cursor: progress;
    color: #a9a9a9;
  }

  label::before,
  label::after {
    transition: all 0.25s ease-in;
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    margin-top: 5px;
    height: 20px;
    width: 20px;

    border: 1px solid #c9c9ca;
    border-radius: 100%;

    left: -17px;
    top: -8px;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 6px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;

    transform: rotate(-45deg);

    left: -12px;
    top: 2px;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background: #6579f3;
    border-color: #6579f3;
    border-radius: 100%;
  }
`
const Checkbox = ({ labelText, id, ...rest }) => {
  return (
    <CheckboxContainer>
      <input type="checkbox" id={id} {...rest} />
      <label htmlFor={id}>{labelText}</label>
    </CheckboxContainer>
  )
}
const advertisements = [
  "Google",
  "Twitter",
  "BlackHatWorld",
  "FBGroup",
  "Facebook",
  "Other",
  "Instagram",
]
const AccountCreationInfo = ({
  handleAccountCreateSurvey,
  successMessage,
  submitting,
  authError,
}) => {
  const [activeSelection, setActiveSelection] = useState("")
  const [serviceProvider, setServiceProvider] = useState("")
  const [productLocation, setProductLocation] = useState("")
  const [loading, setLoading] = useState(false)
  const [serviceProviderError, setServiceProviderError] = useState("")
  const [productLocationError, setProductLocationError] = useState("")
  const serviceProviderInputRef = useRef(null)
  const productLocationInputRef = useRef(null)

  const onSubmit = async () => {
    if (loading) {
      return
    }
    try {
      const sources = activeSelection.replace("FBGroup", "Facebook Group")
      handleAccountCreateSurvey({
        surveyData: {
          sourceFromWhereUserHeardUs: sources,
          serviceProvider,
          locationToOffer: productLocation,
        },
      })
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const isDisabled = () => {
    if (loading) {
      return true
    }
    if (!activeSelection || activeSelection.length === 0) {
      return true
    }
    if (
      !serviceProvider ||
      serviceProvider.length === 0 ||
      serviceProviderError.length > 0 ||
      productLocationError.length > 0
    ) {
      return true
    }
  }

  const onCompetitorInfoChange = e => {
    setServiceProvider(e.target.value)
    if (!/^[A-Za-z0-9 -]*$/.test(e.target.value)) {
      setServiceProviderError("Must contain only digits and alphabets")
      ReactTooltip.show(serviceProviderInputRef.current)
      return
    }
    setServiceProviderError("")
    ReactTooltip.hide(serviceProviderInputRef.current)
  }

  const handleProductLocationChange = loc => {
    if (loc.length === 0) {
      setProductLocation("")
      setProductLocationError("")
      ReactTooltip.hide(productLocationInputRef.current)
      return
    }
    setProductLocationError("Please select city/state from the list")
    ReactTooltip.show(productLocationInputRef.current)
  }

  return (
    <AccountCreationInfoContainer>
      <div className="section-wrapper hear-about-us-wrapper">
        <h4 className="info-title">Where did you hear about us?</h4>
        <div className="hear-about-options">
          {advertisements.map((r, i) => (
            <Checkbox
              checked={activeSelection.includes(r)}
              onChange={() => {
                const selected =
                  activeSelection.length === 0 ? [] : activeSelection.split(",")
                const selectedOptionIndex = selected.indexOf(r)
                if (selectedOptionIndex > -1) {
                  selected.splice(selectedOptionIndex, 1)
                } else {
                  selected.push(r)
                }
                setActiveSelection(selected.join(","))
              }}
              id={i}
              key={i}
              labelText={r.replace("FBGroup", "Facebook Group")}
            />
          ))}
        </div>
      </div>
      <div className="section-wrapper">
        <h5 className="info-subtitle">What provider are you coming from?</h5>
        <React.Fragment>
          <div className="input-container">
            <input
              id="competitor-info"
              type="text"
              onChange={onCompetitorInfoChange}
              ref={serviceProviderInputRef}
              placeholder="Competitor-info"
              data-tip={serviceProviderError}
              data-event="focus"
              data-multiline={true}
              data-effect="solid"
            />
          </div>
        </React.Fragment>
      </div>
      <div className="section-wrapper">
        <h5 className="info-subtitle">
          Please let us know if you were looking for another <br />
          US location we don’t offer yet
        </h5>
        <React.Fragment>
          <div className="input-container">
            <p
              className="optional-text"
              ref={productLocationInputRef}
              data-tip={productLocationError}
              data-event="focus"
              data-multiline={true}
              data-effect="solid"
            >
              *Optional
            </p>
            <Autocomplete
              suggestions={usCitiesStates}
              onSuggestionSelected={location => {
                setProductLocation(location)
                setProductLocationError("")
                ReactTooltip.hide(productLocationInputRef.current)
              }}
              onInputChange={handleProductLocationChange}
            />
          </div>
        </React.Fragment>
      </div>
      <div className="section-wrapper">
        <PosedError show={authError} children={authError} />
        {successMessage && <h4>{successMessage}</h4>}
        <button
          disabled={isDisabled()}
          className={[
            "button button button--continue",
            submitting ? "submitting" : "",
          ].join(" ")}
          onClick={onSubmit}
        >
          Continue
        </button>
      </div>
    </AccountCreationInfoContainer>
  )
}

export default AccountCreationInfo
