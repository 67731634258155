import React from "react"
import styled from "styled-components"
import posed, { PoseGroup } from "react-pose"
import { parse } from "query-string"
import get from "lodash/get"

import { Link, navigate } from "gatsby"
import ReactTooltip from "react-tooltip"
import withLayout from "../components/layout"
import root from "../utils/windowOrGlobal"

import rpAndYou from "../imgs/rockproxies-and-you.png"
import emailIcon from "../imgs/login-email.svg"
import passwordIcon from "../imgs/login-password.svg"
import AccountCreationInfo from "../components/AccountCreationInfo/AccountCreationInfo"
import { toastSuccess } from "../utils/myToasts"

const Container = styled.div`
  max-width: 440px;
  padding: 0 20px;
  margin: 0 auto;

  text-align: center;

  .illustration {
    max-width: 100%;
    margin: 40px auto;

    @media (max-width: 960px) {
      max-width: 50%;
      margin: 20px auto;
    }
  }

  input,
  .login-button {
    width: 100%;

    border: 2px solid #667af4;
    box-sizing: border-box;
    border-radius: 5px;

    font-size: 14px;
    line-height: 16px;

    padding: 12px;
    padding-left: 22%;
  }

  .input-container {
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  label {
    display: none;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-bottom: 7px;
    margin-top: 7px;
  }

  a {
    color: #667af4;
    text-decoration: none;
    font-size: 14px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    & > button {
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-radius: 4px;
      max-width: 180px;
      font-weight: 700;
      background: #667af4;
      padding: 12px 0;
      text-align: center;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 10px;
      cursor: pointer;

      &:disabled {
        background: #c4c7ce;
        cursor: not-allowed;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column;
      & > button {
        max-width: 100%;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .login-button {
    font-weight: 700;
    background: #667af4;
    padding: 12px;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    &:disabled {
      border: none;
      background: #c4c7ce;
      cursor: not-allowed;
    }
  }

  .submitting {
    opacity: 0.7;
    pointer-events: none;
  }

  .block {
    display: block;
  }

  .large {
    margin: 0 auto;
    width: 805;
    text-align: center;
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .policy-container {
    margin: 0 auto;
    width: 80%;
    text-align: center;
  }
  .competitor-container {
    padding-top: 30px;
  }
`

const IconContainer = styled.div`
  border-right: 2px solid #667af4;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  max-width: 70%;
  max-height: 60%;
`

const ErrorMessage = posed.p({
  visible: {
    opacity: 1,
    scaleY: 1,
  },
  hidden: {
    opacity: 0,
    scaleY: 0,
  },
})

const MAXIMUM_PASSWORD_LENGTH = 32

export function getPasswordError(password, getAll) {
  let errors = []

  if (!/[a-z]/g.test(password)) {
    errors.push("Should contain lower case letters (a-z)")
  }

  if (!/[A-Z]/g.test(password)) {
    errors.push("Should contain upper case letters (A-Z)")
  }

  if (!/[0-9]/g.test(password) && !/[!@#$%^&*]/g.test(password)) {
    errors.push("Should contain numbers (0-9) or special characters (!@#$%^&*)")
  }

  if (String(password).length < 8) {
    errors.push("At least 8 characters in length")
  }

  if (String(password).length > MAXIMUM_PASSWORD_LENGTH) {
    errors.push(`Maximum ${MAXIMUM_PASSWORD_LENGTH} characters in length`)
  }

  return getAll ? errors : errors[0]
}

class PosedError extends React.Component {
  render() {
    const { show, children } = this.props

    return (
      <PoseGroup>
        {show && (
          <ErrorMessage
            key="error"
            className="error"
            pose={children ? "visible" : "hidden"}
            children={children}
          />
        )}
      </PoseGroup>
    )
  }
}

const FORM_TYPES = {
  SELECT_METHOD: "SELECT_METHOD",
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  REQUEST_RESET: "REQUEST_RESET",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_SUCCEED: "RESET_SUCCEED",
  ACCOUNT_SURVEY: "ACCOUNT_SURVEY",
}

const LOGIN_STAGE = {
  EMAIL_INPUT: "EMAIL_INPUT",
  PASSWORD_INPUT: "PASSWORD_INPUT",
}

const METHOD = {
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
}
class CreateAccount extends React.Component {
  state = {
    submittedOnce: false,
    submitting: false,
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    forceUpdate: "",
    recaptchaID: "recaptcha-box-" + Date.now(),
    method: "",
    forgotPwMessage: "",
    validSurvey: false,
  }

  get formType() {
    let { form } = parse(this.props.location.search)

    if (form && FORM_TYPES[form.toUpperCase()]) {
      return form.toUpperCase()
    }

    return FORM_TYPES.SIGNUP
  }

  get loginStage() {
    const { stage } = parse(this.props.location.search)
    if (stage && LOGIN_STAGE[stage.toUpperCase()]) {
      return stage.toUpperCase()
    }
    return this.formType === FORM_TYPES.LOGIN ? LOGIN_STAGE.EMAIL_INPUT : ""
  }

  get submitLabel() {
    switch (this.formType) {
      case FORM_TYPES.LOGIN:
      case FORM_TYPES.SIGNUP:
      case FORM_TYPES.SELECT_METHOD:
        return "Continue"
      case FORM_TYPES.RESET_PASSWORD:
        return "Change Password"
      default:
        return "Request Password Reset"
    }
  }

  get shouldDisableContinueButton() {
    switch (this.formType) {
      case FORM_TYPES.LOGIN:
      case FORM_TYPES.REQUEST_RESET:
      case FORM_TYPES.SELECT_METHOD:
        return !this.state.email || this.state.emailError
      case FORM_TYPES.SIGNUP:
        return (
          !this.state.email ||
          this.state.emailError ||
          this.state.passwordError ||
          !this.state.password
        )
      case FORM_TYPES.RESET_PASSWORD:
        return !this.state.password || this.state.passwordError
      default:
        return true
    }
  }

  get getPageTitle() {
    switch (this.formType) {
      case FORM_TYPES.LOGIN:
        return this.loginStage === LOGIN_STAGE.EMAIL_INPUT
          ? "Log in to continue"
          : "Welcome back!"
      case FORM_TYPES.SIGNUP:
        return "Create your account"
      case FORM_TYPES.RESET_SUCCEED:
        return (
          this.state.forgotPwMessage ||
          "We have sent you an email to reset your password"
        )
      default:
        return ""
    }
  }

  get shouldShowEmailInput() {
    return (
      this.formType !== FORM_TYPES.RESET_PASSWORD &&
      this.loginStage !== LOGIN_STAGE.PASSWORD_INPUT
    )
  }

  get shouldShowPasswordInput() {
    if (this.formType === FORM_TYPES.LOGIN) {
      return this.loginStage === LOGIN_STAGE.PASSWORD_INPUT
    }
    return (
      this.formType === FORM_TYPES.SIGNUP ||
      this.formType === FORM_TYPES.RESET_PASSWORD
    )
  }

  get shouldShowForgetPWButton() {
    return (
      this.formType === FORM_TYPES.LOGIN &&
      this.loginStage === LOGIN_STAGE.PASSWORD_INPUT
    )
  }

  get shouldShowAccountSurveyForm() {
    return this.formType === FORM_TYPES.ACCOUNT_SURVEY
  }

  componentDidMount() {
    if (localStorage.user) {
      return (root.location.href = localStorage.targetUrl || "/proxies")
    }
    if (this.loginStage === LOGIN_STAGE.PASSWORD_INPUT && !this.state.email) {
      return (root.location.href = "/createaccount?form=login")
    }
    if (
      this.formType === FORM_TYPES.ACCOUNT_SURVEY &&
      !this.state.validSurvey
    ) {
      return (root.location.href = "/createaccount?form=signup")
    }
  }

  componentDidUpdate({ location }) {
    if (location.search !== this.props.location.search) {
      this.setState({ successMessage: null, password: "" })
      ReactTooltip.hide(this.passwordRef)
      if (this.props.authStore.state.error) {
        this.props.authStore.clearErrors(this.forceUpdate)
      }
    }
    if (this.loginStage === LOGIN_STAGE.PASSWORD_INPUT && !this.state.email) {
      return (root.location.href = "/createaccount?form=login")
    }
  }

  forceUpdate = () => {
    this.setState({ forceUpdate: Math.random() })
  }

  validatePassword = e => {
    const password = e.target.value
    const passwordError = getPasswordError(password)

    this.setState(prevState => {
      return {
        ...prevState,
        ...(this.formType === FORM_TYPES.SIGNUP ||
        this.formType === FORM_TYPES.RESET_PASSWORD
          ? { passwordError }
          : { passwordError: "" }),
        password,
      }
    })
  }

  validateEmail = e => {
    const email = e.target.value
    const emailError = /\S+@\S+\.\S+/.test(email) ? "" : "Invalid email address"

    this.setState({ email, emailError })
  }

  handleRecaptchaV2Response = greToken => {
    this.setState({ greToken }, this.handleSubmit)
  }

  renderRecaptchaV2 = () => {
    if (window.grecaptcha && this._widgetID === undefined) {
      const wrapper = document.createElement("div")

      this._widgetID = window.grecaptcha.render(wrapper, {
        sitekey: process.env.GRECAPTCHA_V2_KEY,
        callback: this.handleRecaptchaV2Response,
        theme: "light",
      })

      this.captcha.appendChild(wrapper)
    }
  }

  handleSubmit = async e => {
    e && e.preventDefault && e.preventDefault()

    this.setState({ submittedOnce: true })

    const { formType, loginStage } = this
    const { passwordError, emailError, email, password } = this.state

    if (
      formType === FORM_TYPES.SIGNUP ||
      (formType === FORM_TYPES.LOGIN &&
        loginStage === LOGIN_STAGE.PASSWORD_INPUT)
    ) {
      if (passwordError || emailError || !email || !password) return
    } else if (formType === FORM_TYPES.RESET_PASSWORD) {
      if (passwordError || !password) return
    } else {
      if (emailError || !email) return
    }

    this.setState({ submitting: true })
    let successMessage, greToken, v2, response

    const defaultCallback = () => {
      this.setState({ submitting: false, successMessage })
    }

    let finishCallback = defaultCallback

    if (
      [FORM_TYPES.SIGNUP, FORM_TYPES.REQUEST_RESET, FORM_TYPES.LOGIN].includes(
        formType
      )
    ) {
      if (this.state.greToken) {
        greToken = this.state.greToken
        v2 = true
      } else {
        v2 = false

        try {
          greToken = await window.grecaptcha.execute(
            process.env.GRECAPTCHA_KEY,
            {
              action: "submit",
            }
          )
        } catch (e) {
          console.error(e)
          console.error("Fallback to recaptcha v2")
          return this.renderRecaptchaV2()
        }
      }
    }

    switch (formType) {
      case FORM_TYPES.SELECT_METHOD: {
        navigate(
          `/createaccount?form=login&stage=${LOGIN_STAGE.EMAIL_INPUT.toLocaleLowerCase()}`
        )
        break
      }
      case FORM_TYPES.LOGIN: {
        if (loginStage === LOGIN_STAGE.PASSWORD_INPUT) {
          response = await this.props.authStore.loginUser({
            ...this.state,
            greToken,
            v2,
          })
        } else {
          navigate(
            `/createaccount?form=login&stage=${LOGIN_STAGE.PASSWORD_INPUT.toLocaleLowerCase()}`
          )
        }
        break
      }
      case FORM_TYPES.SIGNUP: {
        response = await this.props.authStore.signup({
          ...this.state,
          greToken,
          v2,
        })
        if (
          response &&
          response.id &&
          response.attributes &&
          response.attributes.email === email
        ) {
          this.setState({ validSurvey: "true" })
          navigate(
            `/createaccount?form=${FORM_TYPES.ACCOUNT_SURVEY.toLocaleLowerCase()}`
          )
        }
        break
      }
      case FORM_TYPES.RESET_PASSWORD: {
        const success = await this.props.authStore.resetPassword(this.state)
        if (success) successMessage = "Password changed! You can now Login."
        break
      }
      case FORM_TYPES.ACCOUNT_SURVEY: {
        successMessage = await this.props.authStore.acccountCreateSurvey({
          ...this.state,
          ...e.surveyData,
        })
        response = successMessage
        toastSuccess(response)
        this.setState({ submitting: false })
        return navigate(`/createaccount?form=login`)
      }
      default: {
        successMessage = await this.props.authStore.requestReset({
          ...this.state,
          greToken,
          v2,
        })
        response = successMessage
        if (formType === FORM_TYPES.REQUEST_RESET) {
          this.setState(prevState => ({
            ...prevState,
            forgotPwMessage: successMessage,
          }))
        }
        break
      }
    }

    if (get(response, "status") === 400) {
      console.error("Fallback to recaptcha v2")
      return this.renderRecaptchaV2()
    }
    finishCallback()
  }

  get passwordTooltip() {
    if (typeof window === "undefined") return null
    const { password } = this.state
    const errors = getPasswordError(password, true)

    if (
      errors.length > 0 &&
      password !== "" &&
      (this.formType === FORM_TYPES.SIGNUP ||
        this.formType === FORM_TYPES.RESET_PASSWORD)
    ) {
      root.setTimeout(() => ReactTooltip.show(this.passwordRef), 100)
      return errors.join("<br />")
    } else {
      ReactTooltip.hide(this.passwordRef)
    }

    return null
  }

  delayCaptchaIframeRemoving = () => {
    const temporaryNode = document.createElement("div")
    document.body.appendChild(temporaryNode)
    temporaryNode.style.display = "none"

    // move the recaptcha to a temporary node
    while (this.captcha.firstChild) {
      temporaryNode.appendChild(this.captcha.firstChild)
    }

    // delete the temporary node after reset will be done
    setTimeout(() => {
      document.body.removeChild(temporaryNode)
    }, 5000)
  }

  handleRef = ref => {
    if (!ref) return
    this.captcha = ref
  }

  handleSelectMethod = type => {
    if (type === METHOD.LOGIN) {
      navigate(
        `/createaccount?form=login&stage=${LOGIN_STAGE.EMAIL_INPUT.toLocaleLowerCase()}`
      )
    } else if (type === METHOD.SIGN_UP) {
      navigate(`/createaccount?form=signup`)
    }
  }

  componentWillUnmount() {
    ReactTooltip.rebuild()
    if (this._widgetID !== undefined) {
      this.delayCaptchaIframeRemoving()
      if (window.grecaptcha) window.grecaptcha.reset(this._widgetID)
    }
  }

  _renderPageMessage() {
    const pageMessage =
      this.loginStage === LOGIN_STAGE.EMAIL_INPUT
        ? "To purchase a proxy, please login or signup:"
        : this.state.email
    return this.formType === FORM_TYPES.LOGIN && <h5>{pageMessage}</h5>
  }

  render() {
    const { authStore } = this.props
    const { submittedOnce, passwordError, emailError } = this.state
    const authError = authStore.state.error

    return (
      <Container>
        {!this.shouldShowAccountSurveyForm && (
          <>
            <img className="illustration" src={rpAndYou} alt="" />
            <h4 className="page-title">{this.getPageTitle}</h4>
            {this._renderPageMessage()}
            {this.state.successMessage && <h4>{this.state.successMessage}</h4>}

            {this.formType === FORM_TYPES.SELECT_METHOD && (
              <div className="button-group">
                <button
                  children="Create Account"
                  onClick={() => this.handleSelectMethod(METHOD.SIGN_UP)}
                />
                <button
                  children="Login"
                  onClick={() => this.handleSelectMethod(METHOD.LOGIN)}
                />
              </div>
            )}

            {this.formType !== FORM_TYPES.RESET_SUCCEED && (
              <form onSubmit={this.handleSubmit} noValidate="novalidate">
                {this.shouldShowEmailInput && (
                  <React.Fragment>
                    <label htmlFor="email" children="Email" />
                    <div className="input-container">
                      <IconContainer>
                        <Icon src={emailIcon} />
                      </IconContainer>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        onChange={this.validateEmail}
                        placeholder="Email"
                      />
                    </div>
                    <PosedError show={submittedOnce} children={emailError} />
                  </React.Fragment>
                )}

                {this.shouldShowPasswordInput && (
                  <React.Fragment>
                    <label htmlFor="password" children="Password" />
                    <div className="input-container">
                      <IconContainer>
                        <Icon src={passwordIcon} />
                      </IconContainer>
                      <input
                        ref={ref => (this.passwordRef = ref)}
                        data-tip={this.passwordTooltip}
                        data-event="focus"
                        data-multiline={true}
                        data-effect="solid"
                        id="password"
                        type="password"
                        name="password"
                        onChange={this.validatePassword}
                        placeholder="Password"
                      />
                    </div>
                    <PosedError show={submittedOnce} children={passwordError} />
                  </React.Fragment>
                )}

                <div className="recaptcha-container" ref={this.handleRef} />
                <PosedError show={authError} children={authError} />
                <button
                  className={[
                    "login-button",
                    this.state.submitting ? "submitting" : "",
                  ].join(" ")}
                  children={this.submitLabel}
                  disabled={this.shouldDisableContinueButton}
                  type="submit"
                />
              </form>
            )}
            {this.formType === FORM_TYPES.SIGNUP && (
              <h5 className="policy-container">
                By continuing you agree to our{" "}
                <Link to="/termsofservice">terms of service</Link> and{" "}
                <Link to="/privacypolicy">privacy policy.</Link>
              </h5>
            )}

            {this.formType === FORM_TYPES.RESET_SUCCEED && (
              <Link
                to="/createaccount?form=select_method"
                className="login-button large block"
              >
                Back to login/signup
              </Link>
            )}
          </>
        )}

        {this.shouldShowForgetPWButton && (
          <Link to={`/createaccount?form=${FORM_TYPES.REQUEST_RESET}`}>
            Forgot Password?
          </Link>
        )}
        {this.shouldShowAccountSurveyForm && (
          <div className="competitor-container">
            <AccountCreationInfo
              handleAccountCreateSurvey={this.handleSubmit}
              successMessage={this.state.successMessage}
              submitting={this.state.submitting}
            />
          </div>
        )}
      </Container>
    )
  }
}
export { PosedError }
export default withLayout(CreateAccount)
